import React from "react"
import cx from "classnames"
import marked from "marked"
import { graphql, Link } from "gatsby"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "../components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import ppcStyles from "../templates/LandingPPC.module.css"
import additionalStyles from "../templates/WhatWeDo.module.css"
import styles from "./Main.module.css"
import HubspotForm from "components/HubspotForm"
import WeWorkWithSection from "../components/Blocks/WeWorkWithSection"
import Blocks from "components/Blocks"

// eslint-disable-next-line
export const CaseStudiesBlock = ({ data, customers, noTitle }) => {
  return (
    <div className={styles.customers}>
      <Container>
        {noTitle ? null : <h2>{data.mainCustomers.title}</h2>}
        <div className={styles.customerslist}>
          {(customers || []).filter(Boolean).map(({ node }) => (
            <div className={styles.customersitem} key={node.fields.slug}>
              <div className={styles.customersimage}>
                <Img
                  width="150"
                  height="100"
                  loading="lazy"
                  objectFit="scale-down"
                  alt={node.frontmatter.title}
                  title={node.frontmatter.title}
                  fluid={node.frontmatter.logo.childImageSharp.fluid}
                />
              </div>
              {node.frontmatter.mainTitle ? (
                <h3 className={styles.customertitle}>{node.frontmatter.mainTitle}</h3>
              ) : null}
              <p className={styles.customersquote}>
                {node.frontmatter.quote}
              </p>
              <Link to={`/${node.frontmatter.url}`} className={styles.customerslink}>
                Read more ➜
              </Link>
            </div>
          ))}
        </div>
        {data.mainCustomers.buttonUrl ? (
          <Link className={styles.button} to={`/${data.mainCustomers.buttonUrl}`}>{data.mainCustomers.buttonText}</Link>
        ): null}
      </Container>
    </div>
  )
}

// eslint-disable-next-line
export const MainPage = ({ data, customers, blogPosts, ...props }) => (
  <Layout {...props} {...data}>
    <div className={styles.previewMain}>
      <Container className={styles.preview}>
        <div>
          <Title className={cx(styles.previewBannerTitle, additionalStyles.bannertitle)}>
            <span dangerouslySetInnerHTML={{ __html: data.mainPreview.title || ""}} />
          </Title>
          <p className={`${additionalStyles.title} ${additionalStyles.titlesmall} ${styles.previewTitle}`}>{data.mainPreview.bannerTitle}</p>
          <div className={cx(additionalStyles.description, styles.previewDescription)} dangerouslySetInnerHTML={{ __html: marked(data.mainPreview.description || "") }} />
          <Link className={cx(additionalStyles.bannerbutton, additionalStyles.bannerbuttongradient)} to={`${data.mainPreview.bannerButtonUrl}`}>{data.mainPreview.bannerButtonText}</Link>
        </div>
        {data.mainPreview.image && data.mainPreview.image.extension === "svg" ? (
          <div className={styles.previewimage}>
            <img
              width="585"
              height="500"
              alt={data.mainPreview.title}
              title={data.mainPreview.title}
              src={data.mainPreview.image.publicURL}
              loading="lazy"
            />
          </div>
        ) : data.mainPreview.image && data.mainPreview.image.childImageSharp ? (
          <div className={styles.previewimage}>
            <Img
              width="585"
              height="500"
              objectFit="contain"
              alt={data.mainPreview.title}
              title={data.mainPreview.title}
              fluid={data.mainPreview.image.childImageSharp.fluid}
            />
          </div>
        ) : null}
      </Container>
    </div>

    <WeWorkWithSection partners={data.mainCustomers.list} isWhiteBG={true}/>
    {(data.blocks || []).map((block, i) => {
      const Elem = Blocks[block.type]
      return Elem ? <Elem key={i} {...block} {...blogPosts} casestudies={customers}/> : null }
    )}
    {data.form && data.portalId ? (
      <div className={styles.gray} id="contact-form">
        <Container className={ppcStyles.ppcCTAContainer}>
          <div className={cx(additionalStyles.titleContainer, ppcStyles.ppcCTATitle, styles.formtitle)}>
            <div className={styles.subtitle}>{data.formSubTitle}</div>
            <TitleBlock isLarger>{data.formTitle}</TitleBlock>
          </div>
          <div className={ppcStyles.ppcCTAForm}>
            <HubspotForm id={data.form} portalId={data.portalId} />
          </div>
        </Container>
      </div>
    ) : null}
  </Layout>
)

// eslint-disable-next-line
export const MainPageWrapper = ({ data, ...props }) => (
  <MainPage
    {...props}
    blogPosts={data.blogPosts}
    customers={data.customers.edges}
    data={data.markdownRemark.frontmatter}
  />
)

export default MainPageWrapper

export const pageQuery = graphql`
  query MainPage {
    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { templateKey: { eq: "blog" } },
        isFuture: { eq: false }
      }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            tags
            description
            date(formatString: "MMM Do, YYYY")
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 338, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    customers: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { templateKey: { eq: "successstories" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            mainTitle
            date(formatString: "MMM Do, YYYY")
            quote
            logo {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 1200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "main" } }) {
      frontmatter {
        formTitle
        formSubTitle
        form
        portalId
        seo {
          title
          description
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        mainPreview {
          title
          bannerTitle
          bannerButtonText
          bannerButtonUrl
          description
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1024, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        mainCustomers {
          title
          buttonText
          buttonUrl
          list {
            link
            imageAltText
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 200, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
        blocks {
          type
          title
          secondTitle
          thirdTitle
          description
          buttonText
          buttonUrl
          subTitle
          layout
          bgColor
          imagePosition
          imageWidth
          paddingTop
          text
          image {
            extension
            publicURL
            childImageSharp {
              fluid(srcSetBreakpoints: [1200, 1025, 800, 600, 400], quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          secondImage {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          ppcImageWithContent {
            label
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          ppcSiteDataList {
            title
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 150, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          contentWithList {
            secondTitle
            list {
              label
              link
            }
          }
          ppcTestimonial {
            description
            author
            authorPosition
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 150, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
          iconsGridList {
            title
            subtitle
            description
            icon {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 480) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`